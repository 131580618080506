@import '../fonts/stylesheet.css';

// Variables
@regular-font-family: 'AkkuratLLWeb-Regular';
@black-font-family: 'AkkuratLLWeb-Black';
@light-font-family: 'AkkuratLLWeb-Light';


// To make sure global.less doesn't override
// Below is same as normalize.css
h1 {
  margin: 0.67em 0;
}
/**** General Typrography ****/
.normal-regular {
  font-family: @regular-font-family;
  font-style: normal;
}

.black-regular {
  font-family: @black-font-family;
  font-style: normal;
}

.light-regular {
  font-family: @light-font-family;
  font-style: normal;
}
/**** General Typrography ends ****/

/* Heading with AkkuratLLWeb-Regular (Akkuralat LL as shown in Figma) */
.heading-01-regular {
  .normal-regular;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
}

.heading-01-regular-12px-700-weight {
  .normal-regular;
  font-weight: 700;
  font-size: 12px;
  line-height: 140%;
  height: 17px;
  color: #242e2c;
}

.heading-03-regular {
  .normal-regular;
  font-weight: 400;
  font-size: 30px;
  line-height: 36px;
}

.heading-03-bold {
  .heading-03-regular;
  font-weight: 700;
}

.heading-04-regular {
  .normal-regular;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
}

.heading-05-regular {
  .normal-regular;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
}

.heading-05-bold {
  .heading-05-regular;
  font-weight: 700;
}

.heading-06-regular {
  .normal-regular;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
}

.heading-06-light {
  .light-regular;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
}

.heading-06-bold {
  .heading-06-regular;
  font-weight: 700;
}
/* Heading with AkkuratLLWeb-Regular (Akkuralat LL as shown in Figma) ends */

/* Heading with AkkuratLLWeb-Black starts */
.heading-02-black {
  .black-regular;
  font-weight: 900;
  font-size: 3.5rem;
  line-height: 4.2rem;
}

.heading-03-black {
  .black-regular;
  font-weight: 900;
  font-size: 30px;
  line-height: 36px;
}

.heading-04-black {
  .black-regular;
  font-weight: 900;
  font-size: 20px;
  line-height: 24px;
}

/* Heading with AkkuratLLWeb-Black ends */

/* Paragraph with AkkuratLLWeb-Regular starts */
.paragraph-01-regular {
  .normal-regular;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
}

.paragraph-01-light {
  .light-regular;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
}

.paragraph-01-bold {
  .normal-regular;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
}

.paragraph-02-regular {
  .normal-regular;
  font-weight: 400;
  font-size: 1.3rem;
  line-height: 15.6px;
  color: #242e2c;
}

.paragraph-03-regular {
  .normal-regular;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
}

.paragraph-04-regular {
  .normal-regular;
  font-weight: 400;
  font-size: 11px;
  line-height: 145%;
}

.paragraph-04-light {
  .light-regular;
  font-weight: 300;
  font-size: 11px;
  line-height: 145%;
}

.paragraph-03-bold {
  .normal-regular;
  font-weight: 700;
  font-size: 12px;
  line-height: 140%;
  color: #242e2c;
}

/* Paragraph with AkkuratLLWeb-Regular ends */

.paragraph-01-regular-soft-black {
  .paragraph-01-regular;
  padding: 1rem 1rem 1rem 0;
  color: @soft-black-75-opacity;
}

.regular-cap {
  .normal-regular;
  font-weight: 400;
  font-size: 12px;
  line-height: 16.8px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

.regular-cap-01 {
  .regular-cap;
  font-size: 16px;
  line-height: 20px;
}

.regular-cap-02 {
  .regular-cap;
  font-size: 14px;
  line-height: 20px;
}

.button-cap-03 {
  .regular-cap;
  line-height: 18px;
}

html {
  /* 62.5% of 16px browser font size is 10px */
  font-size: 62.5% !important;
}

body {
  font-size: 1.2rem !important; /* 12px */
}

body,
span,
h1,
h2,
h3,
h4,
h5,
h6,
p,
div {
  -webkit-font-feature-settings: normal !important;
  font-feature-settings: normal !important;
  font-variant: normal !important;
}

.desktop .odometer-container {
  top: 60px;
  left: 30px;
}
.laptop .odometer-container {
  top: 40px;
  left: 20px;
}
