.ant-layout-content {
  background-color: #FAFAFA;
  padding: 8px 20px !important;
}

.no-gap {
  padding: 0px !important;
}

.full-mobile-view {
  .mobile-full-cover {
    height: calc(100dvh - 149px); //97px is header and footer height
    overflow: scroll;
  }

  .mobile-full-width {
    width: 100%;
  }
}

.full-height {
  height: 100%;
}

.full-width {
  width: 100%;
}

.flex {
  display: flex;
}

.column {
  flex-direction: column;
}

.row {
  flex-direction: row;
}

.space-between {
  justify-content: space-between;
}

.justify-center {
  justify-content: 'center';
}

.align-start {
  align-items: start;
}
.align-center {
  align-items: center;
}