.full-mobile-view .ant-form-item-label > label {
    .heading-06-regular
}

.mobile-full .ant-form-item-label > label,
.mobile-bottom .ant-form-item-label > label {
    .heading-06-regular;
}

.mobile-full .helper,
.mobile-bottom .helper {
    .paragraph-01-light;
}


.helper {
    .paragraph-04-light;
    margin-top: 5px;
    color: @dark-gray
}

.pointer {
    cursor: pointer;
}

.label-with-subtitle {
    display: flex;
    flex-direction: column;
}

.label-with-button {
    display: flex;
    flex-direction: column;
    .sub-button {
        color: @deep-green;
        margin: 6px 0px;
        display: flex;
        align-items: center;
    }
    .divider {
        width: 5px;
    }
}