.one-third-dimension {
    display: flex;
    flex-wrap: wrap;
    .ant-upload-list {
        width: 100%;
    }
    .ant-upload-list-picture-card-container {
        width: calc((100% / 3) - 10px);
        aspect-ratio: 1/1;
        height: unset
    }

    .ant-upload-select-picture-card {
        width: calc((100% / 3) - 10px);
        aspect-ratio: 1/1;
        height: unset;

        button {
            cursor: pointer;
        }
    }
}

.uploaded-image-preview {
    width: 100%;
    aspect-ratio: 1/ 1;
    object-fit: cover;
    border: 1px solid @gray-border;
    border-radius: 2px;
    padding: 10px;
}

.uploaded-image-preview-button {
    position: absolute;
    top: -10px;
    right: -10px;
    z-index: 1;
}