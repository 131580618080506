.opacity-06 {
  opacity: 0.6;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
  gap: 6px;
  align-items: center;
}

.flex-and-gap-below {
  .flex-column;
  justify-content: center;
  align-items: flex-start;
  gap: 6px;
}

.flex-and-gap-row {
  .flex-row;
  justify-content: center;
  align-items: flex-start;
}

.flex-and-gap-below-right {
  .flex-and-gap-below;
  align-items: flex-end;
}

.flex-and-space-between-gap-below {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  width: 221px;
}

.flex-and-gap-below-center {
  .flex-and-gap-below;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 6px;

}

.flex-right {
  .flex-row;
  justify-content: flex-end;
  align-items: flex-end;
}

.non-clickable {
  pointer-events: none;
  cursor: default;
}

.clickable {
  transition: background-color 0.3s;
}

.clickable:hover {
  cursor: pointer;
  color: red !important;
}