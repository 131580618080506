.fixed-title-pageheader .ant-page-header-ghost {
  position: sticky;
  top: 0;
  z-index: 2;
  background: white;
}

.page-header {
  transition: border-bottom 0.3s ease;
}

.page-header.with-border {
  border-bottom: 1px solid #e8e8e8;
}