.fixed-title-modal .ant-modal-footer {
  position: sticky;
  bottom: 0;
  z-index: 2;
  background: white;
  width: 100%;
}

.mobile-no-title {
  .ant-modal-content {
    padding-top: 51px; //header height
  }
}

.no-title {
  .ant-modal-body {
    padding-top: 50px;
  }
}

.below-header {
  z-index: 1;
  line-height: 0;
  position: absolute;
  top: 0px;
  left: 0px;
  .ant-progress-inner {
    border-radius: 0px;
  }
  .ant-progress-bg {
    background-color: @primary;
  }
}

.mobile-header {
  position: sticky;
}

.mobile-modal-body {
  padding: 24px;
}

.ant-modal-body {
  position: relative;
}

.ant-modal-footer span {
  text-transform: uppercase;
}

.mobile-full {
  top: 0px !important;
  height: 100dvh;
  max-width: 100vw;
  margin: 0px;



  .ant-modal-close {
    left: 0;
  }
  .ant-modal-close-x {
    width: 51px;
    height: 51px;
    line-height: 51px;
  }

  .ant-modal-close-x svg {
    fill: @black;
  }
  .ant-modal-header {
    border-bottom: 0px;
    padding: 16px 40px;
  }
  .ant-modal-title {
    .heading-06-bold;
    text-align: center;
  }
  .ant-modal-content {
    height: 100dvh;
  }
  .ant-modal-footer {
    text-align: center;
    display: flex;
    justify-content: space-between;
    .ant-btn {
      .regular-cap-01
    }
  }

  .ant-modal-body {
    height: calc(100dvh - 104px);
    overflow: scroll;
    padding: 0px;
  }
}

.mobile-bottom {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding-bottom: 0;
  max-width: 100vw;
  margin: 0;

  .ant-modal-content {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    overflow: hidden;
    max-height: 100dvh;
  }

  .ant-modal-body {
    max-height: calc(100dvh - 128px);
    overflow: scroll;
    padding: 0;
  }

  .ant-modal-title {
    .heading-05-regular;
    display: flex;
    justify-content: center;
  }

  .ant-modal-footer {
    text-align: center;
    display: flex;
    justify-content: space-between;
    .ant-btn {
      .regular-cap-01
    }
  }
}

/* Animation for modal entering (appearing from bottom) */
@keyframes slideInFromBottom {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Animation for modal leaving (disappearing to bottom) */
@keyframes slideOutToBottom {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(100%);
    opacity: 0;
  }
}

/* Enter transition class for the modal */
.modal-slide-enter,
.modal-slide-appear {
  transform: translateY(100%);
  opacity: 0;
}

.modal-slide-enter-active,
.modal-slide-appear-active {
  animation: slideInFromBottom 0.3s ease-out forwards;
}

/* Leave transition class for the modal */
.modal-slide-leave {
  transform: translateY(0);
  opacity: 1;
}

.modal-slide-leave-active {
  animation: slideOutToBottom 0.3s ease-out forwards;
}
