/* Styling for all input elements */
.ant-input {
    border-radius: 40px !important;
    // height: 38px;
}

// custom class to add extra padding-left for file inputs
.custom-input {
    .ant-input[type="file"] {
        padding-left: 9px; // Adding 9px to the existing 9px padding
    }
}

.full-mobile-view .ant-input,
.mobile-full .ant-input,
.mobile-bottom .ant-input
 {
    .paragraph-01-regular
}

/* Styling for Ant Design's input elements */
// .ant-input {
//     -webkit-font-feature-settings: normal!important;
//     font-feature-settings: normal!important;
//     font-variant: normal!important;
// }

/* Hover effect for Ant Design's input elements */
.ant-input:hover {
    border-color: #30d7ba;
}

/* Focus effect for Ant Design's input elements */
.ant-input:focus {
    border-color: #30d7ba;
    box-shadow: none;
}

/* Styling for the placeholder text of Ant Design's input elements */
// .ant-input::placeholder {
//     -webkit-font-feature-settings: normal !important;
//     font-feature-settings: normal !important;
//     font-variant: normal !important;
// }

