.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 48px;
    // border: 1px solid #dedede;
    // background-color: #ffffff;
    // height: 38px;
  }
//   .ant-select-selection-overflow {
//     margin-left: 25px;
//   }

//   .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
//     border-color: #30d7ba;
//     border-left-width: 1px;
//     border-right-width: 1px;
//   }

  .ant-select:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input):focus-within .ant-select-selector {
    // border-color: #30d7ba;
    box-shadow: none;
    outline: 0;
    // border-right-width: 1px;
 }

//   .ant-select-selection-item,
//   .ant-select-multiple .ant-select-selection-item {
//     background-color: rgba(48, 215, 186, 0.1);
//     border: 0;
//   }

//   .ant-select-single.ant-select-show-arrow .ant-select-selection-item, .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
//     background-color: transparent;
//   }

//   // TODO: get the last selected tenants and add a border-bottom line
//   // .rc-virtual-list-holder-inner div.ant-select-item.ant-select-item-option.ant-select-item-option-selected {
//   //   border-bottom: 1px red solid;
//   // }
//   // .ant-select-selection-item-remove {
//   //   display: none !important;
//   // }

//   .ant-cascader-checkbox-inner {
//     border-radius: 50%;
//   }

//   .ant-cascader-checkbox-checked::after {
//     border: 0;
//     border-color: #30d7ba;
//   }

//   .ant-cascader-checkbox:hover .ant-cascader-checkbox-inner {
//     border-color: #54dec7;
//   }

//   .ant-cascader-checkbox-checked .ant-cascader-checkbox-inner,
//   .ant-cascader-checkbox-indeterminate .ant-cascader-checkbox-inner {
//     background-color: #54dec7;
//   }

//   .ant-cascader-checkbox-checked .ant-cascader-checkbox-inner::after {
//     border-color: #000000;
//   }

//   .ant-cascader-menu-item[aria-checked='true'],
//   .ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled),
//   .ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled):hover {
//     background: #fff;
//   }

//   .ant-cascader-checkbox-indeterminate .ant-cascader-checkbox-inner::after {
//     position: absolute;
//     display: table;
//     border: 2px solid #fff;
//     border-top: 0;
//     border-left: 0;
//     -webkit-transform: rotate(45deg) scale(1) translate(-50%, -50%);
//     transform: rotate(45deg) scale(1) translate(-50%, -50%);
//     opacity: 1;
//     transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
//     width: 5.71428571px;
//     height: 9.14285714px;
//     top: 50%;
//     left: 21.5%;
//     border-color: #000;
//     background-color: #30d7ba;
//     content: ' ';
//   }

//   .ant-select-item-option-active:not(.ant-select-item-option-disabled){
//     background-color: rgba(48, 215, 186, 0.1);
//   }

//   .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
//     background-color: white;
//   }

//   .ant-select-multiple.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
//     background: white;
//   }

.ant-select-selection-placeholder {
  font-family: 'AkkuratLLWeb-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}

.ant-select-multiple span.ant-select-selection-item {
  background-color: rgba(48, 215, 186, 0.1);
  border-radius: 17px;
}

.ant-select-selection-item,
.ant-select-multiple .ant-select-selection-item {
  // background-color: @neonGreen10;
  border: 0;
}