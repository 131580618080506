.app-header.ant-menu-item {
    padding-top: 0px !important;
}

.mobile-header {
    height: fit-content !important;

    .ant-menu {
        align-items: start !important;
        margin-top: 20px;
    }
}
