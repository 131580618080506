/* Define the hover effect for the action icons of the card component */
.custom-action-icon {
  transition: background-color 0.5s;
  padding: 8px; /* Add padding to make the hover area larger */
}

.custom-action-icon:hover {
  background-color: white;
  border-color: #40a9ff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
