  // Only applied to chargers table for now but may apply to every table later
  .chargers-table .ant-table-expanded-row {
    display: none;
  }

  .custom-row-collapse > td > * {
    max-height: 50px;
    overflow: hidden;
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
  }

  // This is for the fixed title modal can is used in charger get configuration
  .fixed-title-modal .ant-modal-header {
    position: sticky;
    top: 0;
    z-index: 2;
    background: white;
  }

  .ant-table-tbody > tr > td,
  .ant-table-thead > tr > th {
    border-bottom: 1px solid #dedede;
  }

.ant-table-thead> tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  background: none;
  width: none; // <-- add 1px to bring back the column divider between the table's header
}

.table-dot-dropdown {
  border-radius: 5px;
  padding: 5px 10px;

  li {
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

  li:not(:first-child) {
    padding-top: 7.5px
  }

  p {
    margin-bottom: 0px;
  }
}