.ant-checkbox-inner {
  border-radius: 5px;
}

.ant-checkbox-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 16px;
  > label {
    .heading-06-regular;
  }
}
