.custom-message-banner {
    padding: 10px 15px;
    border-radius: 5px;

    .ant-notification-notice {
        padding: 10px 15px;
    }

    .ant-notification-notice-message {
        margin-bottom: 0px;
    }

    svg {
        margin-bottom: 8px;
    }

    .ant-checkbox-checked .ant-checkbox-inner {
        background-color: #000000;
        border-color: #000000;
    }
}

.success {
    background: #30D7BA;

    .ant-checkbox-checked .ant-checkbox-inner::after {
        border-color: #30D7BA;
    }
}

.warning {
    background: #FF9900;

    .ant-checkbox-checked .ant-checkbox-inner::after {
        border-color: #FF9900;
    }
}

.error {
    background: #d9534f;

    .ant-checkbox-checked .ant-checkbox-inner::after {
        border-color: #d9534f;
    }
}